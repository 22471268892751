import React, { useEffect, useState } from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import PaymentForm, { loadSquareSdk } from "../components/paymentForm";

const IndexPage = () => {
  const [squareStatus, setSquareStatus] = useState(null);

  useEffect(() => {
    loadSquareSdk()
      .then(() => {
        setSquareStatus("SUCCESS");
      })
      .catch(() => setSquareStatus("ERROR"));
  }, []); // on mount, add the js script dynamically

  return (
    <Layout>
      <SEO title="Home" />

      {squareStatus === "ERROR" &&
        "Failed to load SquareSDK. Please refresh the page."}
      {squareStatus === "SUCCESS" && (
        <PaymentForm paymentForm={window.SqPaymentForm} amount={1} />
      )}
    </Layout>
  );
};

export default IndexPage;
